export default {
    header: {
        self: {},
        items: [{}],
    },
    aside: {
        self: {},
        items: [
            {
                title: "Dashboard",
                root: true,
                icon: "fas fa-tachometer-alt",
                page: "dashboard",
                bullet: "dot",
            },
            {
                title: "Cadastros",
                root: true,
                icon: "fas fa-users",
                bullet: "dot",
                submenu: [
                    {
                        title: "Jogadores",
                        bullet: "dot",
                        page: "jogador",
                    },
                    {
                        title: "Times",
                        bullet: "dot",
                        page: "time",
                    },
                    {
                        title: "Campeonatos",
                        bullet: "dot",
                        page: "campeonato",
                    },
                    {
                        title: "Jogos",
                        bullet: "dot",
                        page: "jogo",
                    },
                    {
                        title: "Elenco",
                        bullet: "dot",
                        page: "elenco",
                    },
                    {
                        title: "Embaixadas/Consulados",
                        bullet: "dot",
                        page: "embaixada",
                    },
                    {
                        title: "Torcedores",
                        bullet: "dot",
                        page: "usuario",
                    },
                ],
            },
            {
                title: "Notificações",
                root: true,
                icon: "fas fa-bell",
                page: "notificacao",
                bullet: "dot",
            },
            {
                title: "Acessos",
                root: true,
                icon: "fas fa-user-shield",
                bullet: "dot",
                submenu: [
                    {
                        title: "Administradores",
                        page: "admin",
                        bullet: "dot",
                    },
                    {
                        title: "Grupos de Acesso",
                        page: "group",
                        bullet: "dot",
                    },
                ],
            },
        ],
    },
};
