import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

const GroupPage = lazy(() => import("./group/GroupPage"));

const AdminPage = lazy(() => import("./admin/AdminPage"));

const DashboardPage = lazy(() => import("./dashboard/DashboardPage"));

const UsuarioPage = lazy(() => import("./usuario/UsuarioPage"));

const EmbaixadaPage = lazy(() => import("./embaixada/EmbaixadaPage"));

const TimePage = lazy(() => import("./time/TimePage"));

const JogadorPage = lazy(() => import("./jogador/JogadorPage"));

const ElencoPage = lazy(() => import("./elenco/ElencoPage"));

const CampeonatoPage = lazy(() => import("./campeonato/CampeonatoPage"));

const JogoPage = lazy(() => import("./jogo/JogoPage"));

const NotificacaoPage = lazy(() => import("./notificacao/NotificacaoPage"));

export default function HomePage() {
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {<Redirect exact from="/" to="/dashboard" />}

                <Route path="/dashboard" component={DashboardPage} />
                <Route path="/usuario" component={UsuarioPage} />
                <Route path="/group" component={GroupPage} />
                <Route path="/admin" component={AdminPage} />
                <Route path="/embaixada" component={EmbaixadaPage} />
                <Route path="/time" component={TimePage} />
                <Route path="/jogador" component={JogadorPage} />
                <Route path="/elenco" component={ElencoPage} />
                <Route path="/campeonato" component={CampeonatoPage} />
                <Route path="/jogo" component={JogoPage} />
                <Route path="/notificacao" component={NotificacaoPage} />

                <Redirect to="/error/error-v6" />
            </Switch>
        </Suspense>
    );
}
